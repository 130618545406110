import TabComponent from '../../../components/Tab/TabComponent';
import IvrLeadsBaseUpload from './baseupload/IvrLeadsBaseUpload';
const TabData = [

  {
    title: 'IVR Base Upload',
    PageComponent: <IvrLeadsBaseUpload />
  },
]
export default function BaseUpload() {
  return (
    <TabComponent tabData={TabData} />
  );
}