

import TabComponent from '../../../components/Tab/TabComponent';
import CDRReportPage from './report/CDRReportPage';
const TabData = [
  {
    title: 'CDR Report',
    PageComponent: <CDRReportPage />
  },

]
export default function AllCampaigns() {
  return (
    <TabComponent tabData={TabData} />
  );
}